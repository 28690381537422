/**
 * @jsx jsx
 */
import React from 'react';
import { jsx } from '@balance-web/core';
import { Stack } from '@balance-web/stack';
import { Inline } from '@balance-web/inline';
import { DeprecatedButton } from '@balance-web/button-deprecated';

import { CodeBlock } from '../../../common/CodeBlock';

export type ConfirmWizardStepProps = {
  summary: Record<string, any>;
  onSubmitClick: () => void;
};

export const ConfirmWizardStep = ({
  summary,
  onSubmitClick,
}: ConfirmWizardStepProps) => {
  return (
    <Stack as="form" gap="small">
      <CodeBlock code={JSON.stringify(summary, null, 2)} />
      <Inline alignSelf="end">
        <DeprecatedButton
          label="Next"
          tone="active"
          weight="subtle"
          type="submit"
          onClick={onSubmitClick}
        />
      </Inline>
    </Stack>
  );
};
