import { gql } from '@ts-gql/tag';
import { useCallback } from 'react';

import { useMutation } from '@reckon-web/gql-api-client';

const AuthenticateUserMutation = gql`
  mutation AuthenticateUserMutation($email: String!, $password: String!) {
    authenticatePortalUser(data: { email: $email, password: $password }) {
      token
    }
  }
` as import('../../__generated__/ts-gql/AuthenticateUserMutation').type;

export const useAuthenticateUserMutation = () => {
  const [mutate, { loading, error }] = useMutation(AuthenticateUserMutation);

  const login = useCallback(
    async (payload?: Parameters<typeof mutate>[0]['variables']) => {
      if (!payload) return {};
      const { email, password } = payload;
      const { data, errors } = await mutate({ variables: { email, password } });
      return {
        errors,
        token: data?.authenticatePortalUser?.token,
      };
    },
    [mutate]
  );

  return {
    login,
    loading,
    error,
  };
};
