/** @jsx jsx */
import React, { PropsWithChildren } from 'react';
import { jsx } from '@balance-web/core';

import { ReckonBalanceCore } from '@reckon-web/reckon-balance-core';

import { ClipboardProvider } from '../../common/ClipboardButton';

import { UniversalNextLink } from '@balance-web/next-link';

/**
 * Configure the app theme here.
 */
export function BalanceProvider({ children }: PropsWithChildren<{}>) {
  return (
    <ReckonBalanceCore themeMode="light" linkComponent={UniversalNextLink}>
      <ClipboardProvider>{children}</ClipboardProvider>
    </ReckonBalanceCore>
  );
}
