import React from 'react';
import { Text } from '@balance-web/text';
import { plural } from '@balance-web/utils';

export type useRemainingCharactersGuidanceProps = {
  currentValueLength: number;
  maxLength: number;
};

/**
 * A hook that returns a label like '5 characters remaining'.
 * Output of this hook should be used inside `guidance` property of a `Field` component.
 * This hook is aimed to be used by `Textarea` or `TextInput` components.
 *
 * @param currentValueLength - The length of the current value of the input field.
 * @param maxLength - The maximum length of the input field.
 */
export const useRemainingCharactersGuidance = ({
  currentValueLength,
  maxLength,
}: useRemainingCharactersGuidanceProps) => {
  const remainingCharacters = maxLength - currentValueLength;

  return (
    <Text
      color={remainingCharacters <= 0 ? 'critical' : 'dim'}
      overflowStrategy="nowrap"
      size="small"
    >
      {plural(remainingCharacters, 'character', 'characters')} remaining
    </Text>
  );
};
