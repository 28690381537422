/**
 * @jsx jsx
 */

import React, { useCallback } from 'react';
import { jsx } from '@balance-web/core';

import { useDevLauncherWizard } from '../useDevLauncherWizard';

import { useConfirmStep } from './useConfirmStep';
import { ConfirmWizardStep } from './ConfirmWizardStep';

type ConfirmWizardStepContainerProps = {
  onSubmitClick: () => void;
};
export const ConfirmWizardStepContainer = ({
  onSubmitClick,
}: ConfirmWizardStepContainerProps) => {
  const { state } = useConfirmStep();
  const { wizard } = useDevLauncherWizard();

  const handleSubmit = useCallback(async () => {
    state.confirmed = true;
    onSubmitClick();
  }, [onSubmitClick, state]);

  if (wizard.currentStep !== 2) return null;

  return (
    <ConfirmWizardStep
      summary={{
        additionalstate: wizard.additionalState,
        stepstate: wizard.stepsState,
      }}
      onSubmitClick={handleSubmit}
    />
  );
};
