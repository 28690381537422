import React from 'react';
import { MediumReview, SpotCaution, SpotWave } from '@balance-web/illustration';
import { Text } from '@balance-web/text';
import { PlusIcon } from '@balance-web/icon';

import { Meta, StoryFn } from '@reckon-web/storybook';

import { EmptyState, EmptyStateProps } from './EmptyState';

export default {
  title: 'Launch/Common/EmptyState',
  component: EmptyState,
} as Meta;

export const Complete: StoryFn<EmptyStateProps> = (args) => (
  <EmptyState
    image={<MediumReview />}
    actions={[
      {
        action: () => {},
        label: 'Do stuff',
      },
      {
        action: () => {},
        label: 'Do something else',
      },
    ]}
    {...args}
  />
);
Complete.args = {
  title: 'Example Empty State',
};

export const EmptyGuidance: StoryFn<EmptyStateProps> = (args) => (
  <EmptyState
    image={<SpotWave />}
    fullScreen={true}
    content={
      <Text weight="medium" color="muted" size="small">
        Guidance instructions, foo bar baz, foo bar baz
      </Text>
    }
    actions={[
      {
        action: () => {},
        label: 'Do stuff',
      },
    ]}
    {...args}
  />
);
EmptyGuidance.args = {
  title: 'Example EMPTY state guidance',
};

export const ErrorGuidance: StoryFn<EmptyStateProps> = (args) => (
  <EmptyState
    image={<SpotCaution />}
    fullScreen={true}
    content={
      <Text weight="medium" color="muted" size="small">
        Guidance instructions, foo bar baz, foo bar baz
      </Text>
    }
    actions={[
      {
        action: () => {},
        label: 'Do stuff',
      },
    ]}
    {...args}
  />
);
ErrorGuidance.args = {
  title: 'Example ERROR state guidance',
};

export const SubtleButtonWeight: StoryFn<EmptyStateProps> = (args) => (
  <EmptyState
    image={<SpotWave />}
    fullScreen={true}
    content={
      <Text weight="medium" color="muted" size="small">
        Guidance instructions, foo bar baz, foo bar baz
      </Text>
    }
    actions={[
      {
        action: () => {},
        weight: 'subtle',
        label: 'Do stuff',
      },
    ]}
    {...args}
  />
);
SubtleButtonWeight.args = {
  title: 'Example EMPTY state guidance',
};

export const LongInstructions: StoryFn<EmptyStateProps> = (args) => (
  <EmptyState
    image={<SpotCaution />}
    fullScreen={true}
    content={
      <Text weight="medium" color="muted" size="small">
        Guidance instructions, foo bar baz, foo bar baz,Guidance instructions,
        foo bar baz, foo bar baz
      </Text>
    }
    actions={[
      {
        action: () => {},
        weight: 'subtle',
        label: 'Do stuff',
      },
    ]}
    {...args}
  />
);
LongInstructions.args = {
  title: 'Example long instructions',
};

export const WithIconBefore: StoryFn<EmptyStateProps> = (args) => (
  <EmptyState
    image={<SpotWave />}
    fullScreen={true}
    content={
      <Text weight="medium" color="muted" size="small">
        Guidance instructions, foo bar baz
      </Text>
    }
    actions={[
      {
        action: () => {},
        weight: 'subtle',
        iconBefore: PlusIcon,
        label: 'Do stuff',
      },
    ]}
    {...args}
  />
);
WithIconBefore.args = {
  title: 'Example Icon before',
};

export const NoActions: StoryFn<EmptyStateProps> = (args) => (
  <EmptyState
    image={<SpotWave />}
    fullScreen={true}
    content={
      <Text weight="medium" color="muted" size="small">
        Guidance instructions, foo bar baz, foo bar baz
      </Text>
    }
    {...args}
  />
);
NoActions.args = {
  title: 'Example EMPTY state guidance',
};
