import React, { createContext, useContext, useMemo, useState } from 'react';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import Head from 'next/head';
import { useTheme } from '@balance-web/theme';

import { urlWithPublicPrefix } from '@reckon-web/next-config/client';

import { useAuthenticatedUser } from '../../../services/Auth';

type MetaHeadContextShape = {
  currentPageTitle: string | null;
  setCurrentPageTitle: Dispatch<SetStateAction<string>>;
};
const MetaHeadContext = createContext<MetaHeadContextShape>({
  currentPageTitle: null,
  setCurrentPageTitle: () => {},
});

type MetaHeadProps = PropsWithChildren<{
  appName: string;
  version: string;
}>;

export function MetaHead({ appName, version, children }: MetaHeadProps) {
  const { colors } = useTheme();
  const [currentPageTitle, setCurrentPageTitle] = useState('');
  const authenticatedUser = useAuthenticatedUser();

  const tenancyIconURL = useMemo(() => {
    if (authenticatedUser.isLoading) {
      return '';
    }

    return authenticatedUser.user?.tenancyIconURL || '';
  }, [authenticatedUser.isLoading, authenticatedUser.user?.tenancyIconURL]);

  return (
    <MetaHeadContext.Provider value={{ currentPageTitle, setCurrentPageTitle }}>
      <Head>
        {!currentPageTitle && <title>{appName}</title>}
        {!!currentPageTitle && (
          <title>
            {appName} / {currentPageTitle}
          </title>
        )}
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="version" content={version} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={
            tenancyIconURL ||
            urlWithPublicPrefix('/public/apple-touch-icon.png')
          }
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={
            tenancyIconURL || urlWithPublicPrefix('/public/favicon-32x32.png')
          }
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={
            tenancyIconURL || urlWithPublicPrefix('/public/favicon-16x16.png')
          }
        />
        <link
          rel="manifest"
          href={urlWithPublicPrefix('/public/manifest.webmanifest')}
        />
        <link
          rel="mask-icon"
          href={urlWithPublicPrefix('/public/safari-pinned-tab.svg')}
          color={colors.accent}
        />
        <meta name="msapplication-TileColor" content={colors.accent} />
      </Head>
      {children}
    </MetaHeadContext.Provider>
  );
}

export function useMetaHead() {
  const context = useContext(MetaHeadContext);
  if (!context) {
    throw new Error(
      'useMetaHead must only be used within children of MetaHead'
    );
  }
  return context;
}
